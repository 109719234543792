import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button } from '@material-ui/core'

export default ({ open, onCancel, onConfirmation }) => {

    const handleOnConfirmation = (response) => () => {
        onConfirmation?.(response)
    };

    return (
        <Dialog open={open} onClose={onCancel}>
            <DialogTitle>Please indicate your reason for skipping this action.</DialogTitle>
            <DialogContent style={{ display: 'flex', padding: 16, alignItems: 'center' }}>
                <Button style={{ marginRight: 16 }} color="primary" variant="contained" onClick={handleOnConfirmation("DOES_NOT_APPLY")}>
                    Does Not Apply to Me
                </Button>
                <Button style={{ marginRight: 16 }} variant="contained" onClick={handleOnConfirmation("OTHER")}>
                    Other
                </Button>
                <Button style={{ marginLeft: "auto" }} onClick={onCancel}>
                    Cancel
                </Button>
            </DialogContent>
        </Dialog>
    )
}
