import React from 'react';
import Typography from '@material-ui/core/Typography';

const LoadingAppPage = () => (
  <div style={{ background: '#006ab0', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <div style={{ textAlign: 'center' }}>
      <svg width="300px" height="226px" viewBox="0 0 300 226" version="1.1">
        <desc>Agrin Health logo</desc>
        <defs></defs>
        <g
          id="Logo-Comps"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Artboard-3"
            transform="translate(-110.000000, -112.000000)"
            fill="#FFFFFF"
          >
            <g id="Logo-White" transform="translate(110.000000, 112.000000)">
              <g>
                <g
                  id="Ground-White"
                  transform="translate(28.416779, 200.798561)"
                  fillRule="nonzero"
                >
                  <path
                    d="M137.046667,0 C173.574428,1.51019457 207.472239,9.98158319 244,25 C212.281011,14.9494536 178.878141,9.67741935 138.266667,9.67741935 C137.758173,6.43603928 137.265391,3.23408972 137.046667,0 Z"
                    id="Ground-Right"
                  ></path>
                  <path
                    d="M0,0 C36.5277618,1.51019457 70.1498388,9.98158319 106.6776,25 C74.9586107,14.9494536 41.8314745,9.67741935 1.22,9.67741935 C0.711506701,6.43603928 0.218724571,3.23408972 0,0 Z"
                    id="Ground-Left"
                    transform="translate(53.338800, 12.500000) scale(-1, 1) translate(-53.338800, -12.500000) "
                  ></path>
                </g>
                <g
                  id="Trunk"
                  transform="translate(78.349120, 83.733813)"
                  fillRule="nonzero"
                >
                  <path
                    d="M67.898632,126.626596 L64.5411936,110.261557 L63.2514126,126.626596 L58.4964143,126.626596 C65.1026558,83.2746006 46.8702896,39.8756052 11.3275898,14.3493646 L0.357036539,6.47047577 L0.357036539,0.303090958 L16.7350413,11.3260606 C43.4343994,29.2956619 61.7573884,57.3338205 67.5378845,89.0654608 L69.2651353,96.9122812 L73.8310366,96.9122812 L75.5582875,89.0654608 C79.1702071,69.2380735 87.6791232,50.8527415 100.068668,35.464729 C107.507281,26.225852 116.344759,18.0674291 126.361131,11.3260606 L142.739135,0.303090958 L142.739135,6.47047577 L131.768582,14.3493646 C96.2258823,39.8756052 77.9935161,83.2746006 84.5997577,126.626596 L67.898632,126.626596 Z"
                    id="Combined-Shape"
                  ></path>
                </g>
                <g
                  id="Leafs-Right"
                  transform="translate(220.439898, 72.962230) scale(-1, 1) translate(-220.439898, -72.962230) translate(141.278870, 0.000000)"
                  fillRule="nonzero"
                >
                  <g
                    id="Leaf-White"
                    transform="translate(43.210517, 112.269634) rotate(-128.000000) translate(-43.210517, -112.269634) translate(25.758675, 84.082741)"
                  >
                    <path
                      d="M32.8586976,25.2173913 C36.5514305,37.2541897 35.5357641,48.3942951 21.9764297,56 C21.147692,56.4648557 19.7389465,46.1315224 17.7501932,25 C15.8620197,46.2007176 14.4532742,56.534051 13.5239567,56 C0.0403073074,48.2513502 -2.1658304,37.6256393 2.429795,25.2173913 C5.49354526,16.945226 10.6003447,8.53942889 17.7501932,0 C25.3607076,8.78706193 30.3968757,17.192859 32.8586976,25.2173913 Z"
                      id="Leaf-shape"
                    ></path>
                  </g>
                  <g
                    id="Leaf-White"
                    transform="translate(34.276067, 60.834726) rotate(-64.000000) translate(-34.276067, -60.834726) translate(20.321398, 37.970517)"
                  >
                    <path
                      d="M26.2869581,20.7142857 C29.2411444,30.6016558 28.4286113,39.7524567 17.5811437,46 C16.9181536,46.3818458 15.7911572,37.8937505 14.2001546,20.5357143 C12.6896158,37.9505895 11.5626194,46.4386847 10.8191654,46 C0.0322458459,39.6350377 -1.73266432,30.9067751 1.943836,20.7142857 C4.39483621,13.9192928 8.48027573,7.01453087 14.2001546,0 C20.288566,7.21794373 24.3175006,14.1227056 26.2869581,20.7142857 Z"
                      id="Leaf-shape"
                    ></path>
                  </g>
                  <g
                    id="Leaf-White"
                    transform="translate(66.255302, 33.343529) rotate(-13.000000) translate(-66.255302, -33.343529) translate(51.032027, 7.938853)"
                  >
                    <path
                      d="M28.164598,22.9658385 C31.3297976,33.9279228 30.4592264,44.0733759 18.8369397,51 C18.1265932,51.4233507 16.919097,42.0126364 15.2144513,22.7678571 C13.5960169,42.0756536 12.3885208,51.4863678 11.5919629,51 C0.0345491206,43.943194 -1.85642606,34.2662072 2.08268142,22.9658385 C4.70875308,15.4322594 9.08600971,7.77697988 15.2144513,0 C21.7377493,8.00250283 26.0544649,15.6577823 28.164598,22.9658385 Z"
                      id="Leaf-shape"
                    ></path>
                  </g>
                  <g
                    id="Leaf-White"
                    transform="translate(105.312020, 53.011064) rotate(34.000000) translate(-105.312020, -53.011064) translate(90.088745, 30.146855)"
                  >
                    <path
                      d="M28.164598,20.7142857 C31.3297976,30.6016558 30.4592264,39.7524567 18.8369397,46 C18.1265932,46.3818458 16.919097,37.8937505 15.2144513,20.5357143 C13.5960169,37.9505895 12.3885208,46.4386847 11.5919629,46 C0.0345491206,39.6350377 -1.85642606,30.9067751 2.08268142,20.7142857 C4.70875308,13.9192928 9.08600971,7.01453087 15.2144513,0 C21.7377493,7.21794373 26.0544649,14.1227056 28.164598,20.7142857 Z"
                      id="Leaf-shape"
                    ></path>
                  </g>
                  <g
                    id="Leaf-White"
                    transform="translate(128.172989, 81.284826) rotate(65.000000) translate(-128.172989, -81.284826) translate(119.292746, 64.771787)"
                  >
                    <path
                      d="M16.8987588,14.8602484 C18.7978785,21.9533618 18.2755358,28.5180667 11.3021638,33 C10.8759559,33.2739328 10.1514582,27.1846471 9.12867079,14.7321429 C8.15761015,27.2254229 7.43311246,33.3147086 6.95517774,33 C0.0207294724,28.4338314 -1.11385563,22.1722517 1.24960885,14.8602484 C2.82525185,9.9855796 5.45160583,5.03216345 9.12867079,0 C13.0426496,5.17809007 15.6326789,10.1315062 16.8987588,14.8602484 Z"
                      id="Leaf-shape"
                    ></path>
                  </g>
                  <g
                    id="Leaf-White"
                    transform="translate(15.386362, 88.122636) rotate(-91.000000) translate(-15.386362, -88.122636) translate(6.506119, 72.879830)"
                  >
                    <path
                      d="M16.8987588,13.5093168 C18.7978785,19.9576016 18.2755358,25.9255152 11.3021638,30 C10.8759559,30.2490298 10.1514582,24.7133156 9.12867079,13.3928571 C8.15761015,24.7503844 7.43311246,30.2860987 6.95517774,30 C0.0207294724,25.8489376 -1.11385563,20.1565925 1.24960885,13.5093168 C2.82525185,9.07779964 5.45160583,4.57469405 9.12867079,0 C13.0426496,4.70735461 15.6326789,9.2104602 16.8987588,13.5093168 Z"
                      id="Leaf-shape"
                    ></path>
                  </g>
                  <g
                    id="Leaf-White"
                    transform="translate(74.036140, 129.962351) rotate(-159.000000) translate(-74.036140, -129.962351) translate(66.424503, 115.989779)"
                  >
                    <path
                      d="M14.082299,12.6086957 C15.6648988,18.6270949 15.2296132,24.1971475 9.41846986,28 C9.06329659,28.2324279 8.45954852,23.0657612 7.60722566,12.5 C6.79800845,23.1003588 6.19426039,28.2670255 5.79598145,28 C0.0172745603,24.1256751 -0.928213028,18.8128196 1.04134071,12.6086957 C2.35437654,8.472613 4.54300485,4.26971444 7.60722566,0 C10.8688747,4.39353097 13.0272324,8.59642952 14.082299,12.6086957 Z"
                      id="Leaf-shape"
                    ></path>
                  </g>
                  <g
                    id="Leaf-White"
                    transform="translate(37.099204, 31.134767) rotate(-35.000000) translate(-37.099204, -31.134767) translate(29.487567, 17.162195)"
                  >
                    <path
                      d="M14.082299,12.6086957 C15.6648988,18.6270949 15.2296132,24.1971475 9.41846986,28 C9.06329659,28.2324279 8.45954852,23.0657612 7.60722566,12.5 C6.79800845,23.1003588 6.19426039,28.2670255 5.79598145,28 C0.0172745603,24.1256751 -0.928213028,18.8128196 1.04134071,12.6086957 C2.35437654,8.472613 4.54300485,4.26971444 7.60722566,0 C10.8688747,4.39353097 13.0272324,8.59642952 14.082299,12.6086957 Z"
                      id="Leaf-shape"
                    ></path>
                  </g>
                  <g
                    id="Leaf-White"
                    transform="translate(91.644121, 14.137440) rotate(1.000000) translate(-91.644121, -14.137440) translate(84.032484, 0.164868)"
                  >
                    <path
                      d="M14.082299,12.6086957 C15.6648988,18.6270949 15.2296132,24.1971475 9.41846986,28 C9.06329659,28.2324279 8.45954852,23.0657612 7.60722566,12.5 C6.79800845,23.1003588 6.19426039,28.2670255 5.79598145,28 C0.0172745603,24.1256751 -0.928213028,18.8128196 1.04134071,12.6086957 C2.35437654,8.472613 4.54300485,4.26971444 7.60722566,0 C10.8688747,4.39353097 13.0272324,8.59642952 14.082299,12.6086957 Z"
                      id="Leaf-shape"
                    ></path>
                  </g>
                  <g
                    id="Leaf-White"
                    transform="translate(124.702367, 16.779380) rotate(36.000000) translate(-124.702367, -16.779380) translate(118.359336, 5.347275)"
                  >
                    <path
                      d="M12.2046591,10.3571429 C13.5762456,15.3008279 13.1989981,19.8762283 8.16267388,23 C7.85485704,23.1909229 7.33160872,18.9468753 6.5929289,10.2678571 C5.89160733,18.9752947 5.368359,23.2193424 5.02318393,23 C0.0149712856,19.8175188 -0.804451291,15.4533876 0.902495284,10.3571429 C2.04045967,6.95964639 3.93727087,3.50726544 6.5929289,0 C9.41969138,3.60897186 11.2902681,7.06135282 12.2046591,10.3571429 Z"
                      id="Leaf-shape"
                    ></path>
                  </g>
                  <g
                    id="Leaf-White"
                    transform="translate(142.180225, 42.018722) rotate(55.000000) translate(-142.180225, -42.018722) translate(134.568588, 28.046150)"
                  >
                    <path
                      d="M14.082299,12.6086957 C15.6648988,18.6270949 15.2296132,24.1971475 9.41846986,28 C9.06329659,28.2324279 8.45954852,23.0657612 7.60722566,12.5 C6.79800845,23.1003588 6.19426039,28.2670255 5.79598145,28 C0.0172745603,24.1256751 -0.928213028,18.8128196 1.04134071,12.6086957 C2.35437654,8.472613 4.54300485,4.26971444 7.60722566,0 C10.8688747,4.39353097 13.0272324,8.59642952 14.082299,12.6086957 Z"
                      id="Leaf-shape"
                    ></path>
                  </g>
                </g>
                <g id="Leafs-Left" fillRule="nonzero">
                  <g
                    id="Leaf-White"
                    transform="translate(43.210517, 112.269634) rotate(-128.000000) translate(-43.210517, -112.269634) translate(25.758675, 84.082741)"
                  >
                    <path
                      d="M32.8586976,25.2173913 C36.5514305,37.2541897 35.5357641,48.3942951 21.9764297,56 C21.147692,56.4648557 19.7389465,46.1315224 17.7501932,25 C15.8620197,46.2007176 14.4532742,56.534051 13.5239567,56 C0.0403073074,48.2513502 -2.1658304,37.6256393 2.429795,25.2173913 C5.49354526,16.945226 10.6003447,8.53942889 17.7501932,0 C25.3607076,8.78706193 30.3968757,17.192859 32.8586976,25.2173913 Z"
                      id="Leaf-shape"
                    ></path>
                  </g>
                  <g
                    id="Leaf-White"
                    transform="translate(34.276067, 60.834726) rotate(-64.000000) translate(-34.276067, -60.834726) translate(20.321398, 37.970517)"
                  >
                    <path
                      d="M26.2869581,20.7142857 C29.2411444,30.6016558 28.4286113,39.7524567 17.5811437,46 C16.9181536,46.3818458 15.7911572,37.8937505 14.2001546,20.5357143 C12.6896158,37.9505895 11.5626194,46.4386847 10.8191654,46 C0.0322458459,39.6350377 -1.73266432,30.9067751 1.943836,20.7142857 C4.39483621,13.9192928 8.48027573,7.01453087 14.2001546,0 C20.288566,7.21794373 24.3175006,14.1227056 26.2869581,20.7142857 Z"
                      id="Leaf-shape"
                    ></path>
                  </g>
                  <g
                    id="Leaf-White"
                    transform="translate(66.255302, 33.343529) rotate(-13.000000) translate(-66.255302, -33.343529) translate(51.032027, 7.938853)"
                  >
                    <path
                      d="M28.164598,22.9658385 C31.3297976,33.9279228 30.4592264,44.0733759 18.8369397,51 C18.1265932,51.4233507 16.919097,42.0126364 15.2144513,22.7678571 C13.5960169,42.0756536 12.3885208,51.4863678 11.5919629,51 C0.0345491206,43.943194 -1.85642606,34.2662072 2.08268142,22.9658385 C4.70875308,15.4322594 9.08600971,7.77697988 15.2144513,0 C21.7377493,8.00250283 26.0544649,15.6577823 28.164598,22.9658385 Z"
                      id="Leaf-shape"
                    ></path>
                  </g>
                  <g
                    id="Leaf-White"
                    transform="translate(104.890532, 51.243437) rotate(34.000000) translate(-104.890532, -51.243437) translate(90.935864, 29.649462)"
                  >
                    <path
                      d="M26.2869581,19.363354 C29.2411444,28.6058957 28.4286113,37.1599051 17.5811437,43 C16.9181536,43.3569428 15.7911572,35.422419 14.2001546,19.1964286 C12.6896158,35.475551 11.5626194,43.4100748 10.8191654,43 C0.0322458459,37.0501439 -1.73266432,28.8911159 1.943836,19.363354 C4.39483621,13.0115128 8.48027573,6.55706147 14.2001546,0 C20.288566,6.74720827 24.3175006,13.2016596 26.2869581,19.363354 Z"
                      id="Leaf-shape"
                    ></path>
                  </g>
                  <g
                    id="Leaf-White"
                    transform="translate(117.760314, 84.559820) rotate(83.000000) translate(-117.760314, -84.559820) translate(108.880070, 69.317015)"
                  >
                    <path
                      d="M16.8987588,13.5093168 C18.7978785,19.9576016 18.2755358,25.9255152 11.3021638,30 C10.8759559,30.2490298 10.1514582,24.7133156 9.12867079,13.3928571 C8.15761015,24.7503844 7.43311246,30.2860987 6.95517774,30 C0.0207294724,25.8489376 -1.11385563,20.1565925 1.24960885,13.5093168 C2.82525185,9.07779964 5.45160583,4.57469405 9.12867079,0 C13.0426496,4.70735461 15.6326789,9.2104602 16.8987588,13.5093168 Z"
                      id="Leaf-shape"
                    ></path>
                  </g>
                  <g
                    id="Leaf-White"
                    transform="translate(15.386362, 88.122636) rotate(-91.000000) translate(-15.386362, -88.122636) translate(6.506119, 72.879830)"
                  >
                    <path
                      d="M16.8987588,13.5093168 C18.7978785,19.9576016 18.2755358,25.9255152 11.3021638,30 C10.8759559,30.2490298 10.1514582,24.7133156 9.12867079,13.3928571 C8.15761015,24.7503844 7.43311246,30.2860987 6.95517774,30 C0.0207294724,25.8489376 -1.11385563,20.1565925 1.24960885,13.5093168 C2.82525185,9.07779964 5.45160583,4.57469405 9.12867079,0 C13.0426496,4.70735461 15.6326789,9.2104602 16.8987588,13.5093168 Z"
                      id="Leaf-shape"
                    ></path>
                  </g>
                  <g
                    id="Leaf-White"
                    transform="translate(74.036140, 129.962351) rotate(-159.000000) translate(-74.036140, -129.962351) translate(66.424503, 115.989779)"
                  >
                    <path
                      d="M14.082299,12.6086957 C15.6648988,18.6270949 15.2296132,24.1971475 9.41846986,28 C9.06329659,28.2324279 8.45954852,23.0657612 7.60722566,12.5 C6.79800845,23.1003588 6.19426039,28.2670255 5.79598145,28 C0.0172745603,24.1256751 -0.928213028,18.8128196 1.04134071,12.6086957 C2.35437654,8.472613 4.54300485,4.26971444 7.60722566,0 C10.8688747,4.39353097 13.0272324,8.59642952 14.082299,12.6086957 Z"
                      id="Leaf-shape"
                    ></path>
                  </g>
                  <g
                    id="Leaf-White"
                    transform="translate(37.099204, 31.134767) rotate(-35.000000) translate(-37.099204, -31.134767) translate(29.487567, 17.162195)"
                  >
                    <path
                      d="M14.082299,12.6086957 C15.6648988,18.6270949 15.2296132,24.1971475 9.41846986,28 C9.06329659,28.2324279 8.45954852,23.0657612 7.60722566,12.5 C6.79800845,23.1003588 6.19426039,28.2670255 5.79598145,28 C0.0172745603,24.1256751 -0.928213028,18.8128196 1.04134071,12.6086957 C2.35437654,8.472613 4.54300485,4.26971444 7.60722566,0 C10.8688747,4.39353097 13.0272324,8.59642952 14.082299,12.6086957 Z"
                      id="Leaf-shape"
                    ></path>
                  </g>
                  <g
                    id="Leaf-White"
                    transform="translate(91.644121, 14.137440) rotate(1.000000) translate(-91.644121, -14.137440) translate(84.032484, 0.164868)"
                  >
                    <path
                      d="M14.082299,12.6086957 C15.6648988,18.6270949 15.2296132,24.1971475 9.41846986,28 C9.06329659,28.2324279 8.45954852,23.0657612 7.60722566,12.5 C6.79800845,23.1003588 6.19426039,28.2670255 5.79598145,28 C0.0172745603,24.1256751 -0.928213028,18.8128196 1.04134071,12.6086957 C2.35437654,8.472613 4.54300485,4.26971444 7.60722566,0 C10.8688747,4.39353097 13.0272324,8.59642952 14.082299,12.6086957 Z"
                      id="Leaf-shape"
                    ></path>
                  </g>
                  <g
                    id="Leaf-White"
                    transform="translate(118.060366, 16.552830) rotate(36.000000) translate(-118.060366, -16.552830) translate(111.717334, 5.120726)"
                  >
                    <path
                      d="M12.2046591,10.3571429 C13.5762456,15.3008279 13.1989981,19.8762283 8.16267388,23 C7.85485704,23.1909229 7.33160872,18.9468753 6.5929289,10.2678571 C5.89160733,18.9752947 5.368359,23.2193424 5.02318393,23 C0.0149712856,19.8175188 -0.804451291,15.4533876 0.902495284,10.3571429 C2.04045967,6.95964639 3.93727087,3.50726544 6.5929289,0 C9.41969138,3.60897186 11.2902681,7.06135282 12.2046591,10.3571429 Z"
                      id="Leaf-shape"
                    ></path>
                  </g>
                  <g
                    id="Leaf-White"
                    transform="translate(136.258358, 58.851778) rotate(69.000000) translate(-136.258358, -58.851778) translate(129.915327, 47.419674)"
                  >
                    <path
                      d="M12.2046591,10.3571429 C13.5762456,15.3008279 13.1989981,19.8762283 8.16267388,23 C7.85485704,23.1909229 7.33160872,18.9468753 6.5929289,10.2678571 C5.89160733,18.9752947 5.368359,23.2193424 5.02318393,23 C0.0149712856,19.8175188 -0.804451291,15.4533876 0.902495284,10.3571429 C2.04045967,6.95964639 3.93727087,3.50726544 6.5929289,0 C9.41969138,3.60897186 11.2902681,7.06135282 12.2046591,10.3571429 Z"
                      id="Leaf-shape"
                    ></path>
                  </g>
                  <g
                    id="Leaf-White"
                    transform="translate(132.270852, 104.274188) rotate(83.000000) translate(-132.270852, -104.274188) translate(125.927821, 92.842084)"
                  >
                    <path
                      d="M12.2046591,10.3571429 C13.5762456,15.3008279 13.1989981,19.8762283 8.16267388,23 C7.85485704,23.1909229 7.33160872,18.9468753 6.5929289,10.2678571 C5.89160733,18.9752947 5.368359,23.2193424 5.02318393,23 C0.0149712856,19.8175188 -0.804451291,15.4533876 0.902495284,10.3571429 C2.04045967,6.95964639 3.93727087,3.50726544 6.5929289,0 C9.41969138,3.60897186 11.2902681,7.06135282 12.2046591,10.3571429 Z"
                      id="Leaf-shape"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <Typography
        variant="h3"
        style={{ color: 'white', paddingTop: '32px' }}
        gutterBottom
      >
        Get ready...
      </Typography>
      <Typography variant="h5" style={{ color: 'white' }}>
        {' '}
        We're going to make Healthcare easier.{' '}
      </Typography>
    </div>
  </div>
);

export default LoadingAppPage;
