import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Grid,
  CardMedia,
  Button,
  Typography,
  LinearProgress,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { reset } from '../../../../../../store/reducers/syncPermissions/actions';

const state = {
  LOADING: 'loading',
  IDLE: 'idle',
  SUBMITTED: 'submitted',
  DISABLED: 'disabled'
};

const AddSyncPermissions = ({
  bundle,
  handleClose,
  onClick,
  reset,
  activeUser,
}) => {
  const { DISABLED, LOADING, IDLE, SUBMITTED } = state;
  const [modalState, setModalState] = React.useState(bundle.status === "Disabled" ? DISABLED : IDLE);

  const checkStorage = () => {
    const syncAuthorization = JSON.parse(
      window.localStorage.getItem('SYNC_AUTHORIZATION')
    );
    if (!syncAuthorization.isAuthenticating) {
      // window.dispatchEvent(new Event('storage'));
      // window.removeEventListener('storage', checkStorage);
      reset(activeUser);
    }
  };

  const handleClick = () => {
    setModalState(LOADING);
    window.addEventListener('storage', checkStorage);
    onClick(bundle.id, 'add');
  };

  const handleCancel = () => {
    window.localStorage.removeItem('SYNC_AUTHORIZATION');
    window.removeEventListener('storage', checkStorage);
    handleClose();
    setModalState(IDLE);
  };

  const renderContents = () => {
    switch (modalState) {
      case DISABLED: {
        return (
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
            }}
          >
            <Button variant="contained" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              disabled
              variant="contained"
              color="primary"
              onClick={handleClick}
            >
              Coming Soon
            </Button>
          </Grid>
        )
      }

      case SUBMITTED: {
        return (
          <Grid container>
            <Grid item xs={12}>
              <Typography>
                Sync Permissions successfully added for {bundle.name}. You may
                now close this Tab
              </Typography>
            </Grid>
          </Grid>
        );
      }

      case LOADING: {
        return (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <LinearProgress color="secondary" />
              </Grid>
              <Grid item xs={12}>
                <Typography>Syncing {bundle.name}...</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Button variant="contained" onClick={handleCancel}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        );
      }

      case IDLE:
        return (
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
            }}
          >
            <Button variant="contained" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleClick}
            >
              AGREE and ADD
            </Button>
          </Grid>
        );
    }
  };

  return (
    <div
      style={{
        width: '100%',
        maxWidth: '500px',
        height: 'fit-content',
        background: '#fff',
        margin: 'auto',
        marginTop: '36px',
        borderRadius: '3px',
        padding: '40px',
      }}
    >
      <Grid
        container
        spacing={3}
        style={{
          display: 'flex',
          height: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Grid item xs={3}>
          <CardMedia
            image={bundle.image}
            style={{ height: '100px', width: '100px' }}
          />{' '}
          {/*
          <div
            style={{
              height: '100px',
              width: '100px',
              backgroundColor: '#f2f2f2',
            }}
          ></div>*/}
        </Grid>
        <Grid item xs={9} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h4" style={{ fontWeight: '500' }}>
            {bundle.name}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography style={{ fontWeight: '400' }}>
            By clicking <strong>“Agree”</strong> you grant permission for{' '}
            <strong>{bundle.name}</strong> to share information with Agrin
            Health (and your Agrin Health). In addition to personal data
            required to match you to the account, the permission covers the
            following data:
          </Typography>
          <ul>
            {bundle.permissions.map(scope => (
              <li
                key={`permission-scope-${scope}`}
                style={{ textTransform: 'capitalize', listStyle: 'disc' }}
              >
                <Typography>{scope}</Typography>
              </li>
            ))}
          </ul>
        </Grid>
        {renderContents()}
      </Grid>
    </div>
  );
};

const mapStateToProps = state => ({
  activeUser: state.active.user,
});

const mapDispatchToProps = dispatch => ({
  reset: aid => dispatch(reset(aid)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddSyncPermissions)
);
