import React from 'react';
import { Grid, Typography, Paper, Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import { RecommendedActionCard } from './RecommendedActionCard'

const RecommendActionsHeader = () => {
    return (
        <Grid xs={12} style={{ marginBottom: 16 }}>
            <Paper style={{ padding: 16, background: '#f2f2f2' }}>
                <Typography variant="h6">Recommended Actions</Typography>
            </Paper>
        </Grid>
    );
}

const RecommendedActionsList = ({ list, listIds, onComplete, onSkip, onMoreInfo }) => {
    return listIds.map(id => (
        <RecommendedActionCard
            data={list[id]}
            onComplete={onComplete}
            onSkip={onSkip}
            onMoreInfo={onMoreInfo} />
    ))
}

const RecommendActions = ({ status, list, listIds, data, onComplete, onSkip }) => {
    if (status === "FETCHING") {
        return (
            <>
                <RecommendActionsHeader />
                <RecommendActionCardFetching />
            </>
        )
    }

    if (status === "ERROR") {
        return (
            <>
                <RecommendActionsHeader />
                <RecommendActionCardError />
            </>
        )

    }

    if (listIds?.length === 0) {
        return (
            <>
                <RecommendActionsHeader />
                <RecommendActionCardEmpty />
            </>
        )
    }

    return (
        <>
            <RecommendActionsHeader />
            <RecommendedActionsList list={list} listIds={listIds} data={data} onComplete={onComplete} onSkip={onSkip} onMoreInfo={null} />
        </>
    )
}

const RecommendActionCardEmpty = () => {
    return (
        <Paper style={{ padding: 16, marginBottom: 16 }}>
            <Typography>No recommendations at this time</Typography>
        </Paper>
    )
}

const RecommendActionCardError = () => {
    return (
        <Paper style={{ padding: 16, marginBottom: 16 }}>
            <Typography>Something went wrong, please try again later</Typography>
        </Paper>
    )
}

const RecommendActionCardFetching = () => (
    <Paper style={{ padding: 16, marginBottom: 16 }}>
        <Grid xs={12} style={{ marginBottom: 8 }}>
            <Box style={{ display: 'flex', alignItems: 'center' }} component="div">
                <Skeleton variant="rect" style={{ width: 24, height: 24, marginRight: 8 }} animation="wave" />
                <Typography variant="h6">
                    <Skeleton style={{ width: 250 }} animation="wave" />
                </Typography>
            </Box>
            <Typography style={{ color: '#999' }} variant="subtitle2">
                <Skeleton style={{ width: 80 }} animation="wave" />
            </Typography>
        </Grid>
        <Grid xs={12}>
            <Typography><Skeleton style={{ maxWidth: '90%' }} animation="wave" /></Typography>
            <Typography><Skeleton style={{ maxWidth: '75%' }} animation="wave" /></Typography>
        </Grid>
        <Grid xs={12} style={{ marginTop: 16, display: 'flex' }}>
            <Skeleton style={{ width: 90, height: 30.75, marginRight: 16 }} height={30.75} animation="wave" />
            <Skeleton style={{ width: 80, height: 30.75, marginRight: 16 }} height={30.75} animation="wave"/>
            <Skeleton style={{ width: 80, height: 30.75, marginLeft: 'auto' }} height={30.75} animation="wave" />
        </Grid>
    </Paper>
)

export default RecommendActions
