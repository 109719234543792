import React from 'react';
import {
  Modal,
  Grid,
  withStyles,
  IconButton,
  Icon,
  Typography,
} from '@material-ui/core';

const meta = {
  color: '#2AA5A2',
  icon: 'accessibility',
};

const BodyMassViewer = ({ data }) => {
  const calculateBMI = (weight, height) => {
    if (!weight || !height) {
      return '--';
    }
    const bmi = weight / Math.pow(height, 2);
    return bmi.toFixed(2);
  };

  const calculateBMIImperial = (weight, height) => {
    // Conversion factor is calculated by the following:
    // 1. Convert pounds to kilograms: 1 kg ≈ 2.20462 lbs
    // 2. Convert inches to meters: 1 m = 39.3701 inches
    // 3. 39.3701² / 2.20462 ≈ 703
    // X: (lbs * 39.3701² / 2.20462) / inches²
    const CONVERSION_FACTOR = 703
    const result = (weight * CONVERSION_FACTOR) / Math.pow(height, 2)
    return result.toFixed(2)
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <Typography variant="h6">Weight ({data.weightUnit})</Typography>
        <Typography variant="body1">{data.weight}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6">Body Fat (%)</Typography>
        <Typography variant="body1">{data.bodyFatPercentage}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6">Water (%)</Typography>
        <Typography variant="body1">{data.waterPercentage}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6">Muscle Mass (%)</Typography>
        <Typography variant="body1">{data.bodyMassPercentage}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6">BMI</Typography>
        <Typography variant="body1">
          {calculateBMIImperial(data.bmiWeight, data.bmiHeight)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default BodyMassViewer;
