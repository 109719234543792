import { DISPATCH_API_AUTHORIZED } from '../../../library/middleware/dispatchApi';

export const TODO_RECORDS_REQUEST = 'todo/records/REQUEST';
export const TODO_RECORDS_SUCCESS = 'todo/records/SUCCESS';
export const TODO_RECORDS_FAILURE = 'todo/records/FAILURE';

export const TODO_MUTATION_REQUEST = 'todo/record/mutation/REQUEST';
export const TODO_MUTATION_SUCCESS = 'todo/record/mutation/SUCCESS';
export const TODO_MUTATION_FAILURE = 'todo/record/mutation/FAILURE';

export const fetchTodoRecords = (meta) => ({
    [DISPATCH_API_AUTHORIZED]: {
        types: [
            TODO_RECORDS_REQUEST,
            TODO_RECORDS_SUCCESS,
            TODO_RECORDS_FAILURE
        ],
        endpoint: `/api/member/todo`,
        meta
    }
});

export const updateTodoRecord = (id, action, response) => {
    const payload = { id: id, action, response };
    return ({
        [DISPATCH_API_AUTHORIZED]: {
            types: [
                TODO_MUTATION_REQUEST,
                TODO_MUTATION_SUCCESS,
                TODO_MUTATION_FAILURE
            ],
            endpoint: `/api/member/todo`,
            payload,
            meta: {
                config: {
                    headers: { 
                        'Content-Type': 'application/json' 
                    },
                    method: 'PATCH',
                    body: JSON.stringify(payload)
                }
            }
        }
    });
};
