import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

import FlashMessage from '../../containers/FlashMessage';
import styles from '../../components/DashboardContent/AlertsAndInsights.css';
import KnownIssues from './KnownIssues';
import BasePage from '../BasePage';
import { Grid, Button } from '@material-ui/core';

import EasterEgg from '../../../library/components/EasterEggComponents';

const blueHeader = { color: '#0269b0' };

class HelpPage extends Component {
  render() {
    return (
      <BasePage>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item sm={8}>
              <FlashMessage />

              <h3 style={blueHeader}>Known Issues</h3>
              <KnownIssues />

              <h3 style={blueHeader}>Resources</h3>

              <div className={styles.alertsAndInsights}>
                <h3>Frequently Asked Questions</h3>
                <div>
                  <p style={{ borderBottom: 'solid #DDD 1px' }}>
                    Over time this section will contain frequently asked
                    questions and answers.
                    <br />
                    <br />
                  </p>

                  <h3>Training Videos</h3>

                  <p> We’re updating our training videos with the latest functionality, please check back later.</p>
                </div>
              </div>
              {/*
              <div style={{ marginLeft: '30px', marginBottom: '60px' }}>
                <EasterEgg
                  message="You found another egg! CODE: EGGSAREFUN"
                  id="1"
                />
                        </div>
                        */}
            </Grid>

            <Grid item sm={4}>
              <h3 style={blueHeader}>Help Info</h3>
              <p style={{ fontSize: '13pt', color: '#333' }}>
                Email us at <strong>Support@AgrinHealth.com</strong>.<br />
                Our Help Desk is available M-F 9am - 5pm central.
              </p>

              <Button variant="outlined" size="large">
                <Link
                  to="/d/me/help/request"
                  style={{ textDecoration: 'none' }}
                >
                  <span>Request For Help</span>
                </Link>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </BasePage>
    );
  }
}

export default {
  component: withRouter(HelpPage),
};
