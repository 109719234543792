import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Button, Typography, Paper, Box, Popover } from '@material-ui/core';
import { format, parseISO } from 'date-fns'
import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
import defaultIconImage from '../../../client/images/logo.jpg'

import { makeStyles } from '@material-ui/core/styles';
import RecommendedActionDialog from './RecommendedActionDialog';


const AGRIN_HEALTH_PROVIDER_ID = '01920af3-8987-7a61-8eb5-1a3129d3dd37';

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        width: 350,
        padding: theme.spacing(1),
    },
  }));

const RecommendActionCardHeader = ({ data }) => {
    return (
        <Grid style={{ marginBottom: 8 }}>
            <Box style={{ display: 'flex', alignItems: 'center' }} component="div">
               <RecommendedActionHeaderIcon isAgrinHealthProvider={data?.rule?.providerId === AGRIN_HEALTH_PROVIDER_ID} />
               <Typography variant="h6">{ data.title }</Typography>
            </Box>
            <Typography style={{ color: '#999' }} variant="subtitle2">Assigned { format(parseISO(data.createdOn), "MM/dd/yyyy") }</Typography>
        </Grid>
    );
}

const RecommendedActionHeaderIcon = ({ isAgrinHealthProvider }) => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    let IconComponent = null;
    let InfoComponent = null;

    if (isAgrinHealthProvider) {
        IconComponent = (
            <div 
                aria-owns={open ? 'icon-popover' : undefined}
                aria-haspopup="true"
                style={{ display: 'flex', marginRight: 4, alignContent: 'center', alignItems: 'center', width: 32, height: 32 }}>
                <img style={{ width: 30 }} src={defaultIconImage} alt="Agrinhealth intelligent services icon" />
            </div>
        )

        InfoComponent = (
            <Box style={{ display: 'flex', alignItems: 'center', padding: 16, }}>
                <img style={{ width: 96, marginRight: 16 }} src={defaultIconImage} alt="Agrinhealth intelligent services icon" />
                <Typography>
                    <strong>Agrin Health</strong> intelligence service  suggests this action.
                </Typography>
            </Box>
        )

    } else {
        IconComponent = (
            <LocalHospitalIcon 
                aria-owns={open ? 'icon-popover' : undefined}
                aria-haspopup="true"
                style={{ marginRight: 4, fontSize: 32, color: '#C92426' }} />
        );

        InfoComponent = (
            <Box style={{ display: 'flex', alignItems: 'center', padding: 16, }}>
                <LocalHospitalIcon style={{ fontSize: 80, marginRight: 16, color: '#C92426' }} />
                <Typography>
                    Your <strong>Care Team</strong> recommends this action.
                </Typography>
            </Box>
        )
    }

    return (
        <>
            <div onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
                {IconComponent}
            </div>
            
            <Popover
                id="icon-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                {InfoComponent}
            </Popover>
        </>
    )
}

const RecommendActionCardBody = ({ body }) => {
    return (
        <Grid xs={12}>
            <Typography>{ body }</Typography>
        </Grid>
    );
}

const RecommendActionCardFooter = (props) => {

    const [isDialogOpened, setIsDialogOpened] = useState(false) 

    const handleOnComplete = () => {
        props?.onComplete(props.id)
    }

    const handleOnSkip = () => {
        setIsDialogOpened(true)
    }

    const handleTakeAction = () => {
        props?.onTakeAction(props.id)
    }

    // Dialog event handlers
    const handleOnConfirmation = (response) => {
        props?.onSkip(props.id, response)
        setIsDialogOpened(false)
    }

    const handleOnCancel = () => {
        setIsDialogOpened(false)
    }

    return (
        <Grid container item style={{ marginTop: 16 }}>
            <Grid xs={12} style={{ display: 'flex' }} >
                <Button disabled={props.disabled} style={{ marginRight: 16 }} variant="contained" size="small" color="primary" onClick={handleOnComplete}>Complete</Button> 
                <Button disabled={props.disabled} style={{ marginRight: 16 }} variant="contained" size="small" onClick={handleOnSkip}>Skip</Button>
                { props.hasAction ? <Button disabled={props.disabled} style={{ marginLeft: 'auto' }} variant="text" size="small" onClick={handleTakeAction}>Take Action</Button> : null }
            </Grid>
            <RecommendedActionDialog open={isDialogOpened} onConfirmation={handleOnConfirmation} onCancel={handleOnCancel} />
        </Grid>
    );
}

export const RecommendedActionCard = ({ data, onComplete, onSkip, onMoreInfo }) => {
    const history = useHistory();

    const handleTakeAction = () => {
        if (data?.rule?.moduleId === 'e007dae0-451f-40e2-8f83-2fc70b645b94') {
            history.push('resource-library')
        }
    }

    return (
        <Paper style={{ padding: 16, marginBottom: 16 }}>
            <Grid container>
                <RecommendActionCardHeader data={data} />
                <RecommendActionCardBody body={data.description} />
                <RecommendActionCardFooter 
                    data={data}
                    onComplete={onComplete} 
                    onSkip={onSkip} 
                    onTakeAction={handleTakeAction} 
                    hasAction={Boolean(data?.rule?.moduleId)}
                    disabled={data.isMutating} />
            </Grid>
        </Paper>
    );
}

export default RecommendedActionCard
