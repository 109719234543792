/**
 * @typedef {Object} RecommendAction
 * @property {String} id
 * @property {String} type
 * @property {String} title
 * @property {String} description
 * @property {DateTime} createdOn
 * @property {Boolean} isMutating
 */

/**
 * @typedef {Array<RecommendAction>} RecommendedActions
 */

/**
 * @return {RecommendedActions}
 */
export default [
    {
        id: "646ed8ab-8207-46fd-b034-3c1bccfbb7d1",
        type: "Default",
        title: "Task Title",
        description: "This area is for a description of the assigned task or the set of instructions to be provided to the User by the as",
        createdOn: new Date()
    },
    {
        id: "646ed8ab-8207-46fd-b034-3c1bccfbb7d1",
        type: "Default",
        title: "Task Title",
        description: "This area is for a description of the assigned task or the set of instructions to be provided to the User by the as",
        createdOn: new Date()
    },
    {
        id: "646ed8ab-8207-46fd-b034-3c1bccfbb7d1",
        type: "Default",
        title: "Task Title",
        description: "This area is for a description of the assigned task or the set of instructions to be provided to the User by the as",
        createdOn: new Date()
    }
]
