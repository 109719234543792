import {
    TODO_RECORDS_FAILURE, 
    TODO_RECORDS_REQUEST, 
    TODO_RECORDS_SUCCESS,
    TODO_MUTATION_FAILURE, 
    TODO_MUTATION_REQUEST, 
    TODO_MUTATION_SUCCESS,
} from './actions';
import { getKeys, arrayToHashMap } from '../../../library/helpers/utils';

const initialState = {
    list: {},
    listIds: [],
    selectedId: null,
    hasRecords: false,
    status: 'PENDING'
}

const reducers = {};

reducers[TODO_RECORDS_REQUEST] = (state, action) => {
    return {
        ...state,
        status: 'FETCHING'
    }
};

reducers[TODO_RECORDS_SUCCESS] = (state, action) => {

    const list = arrayToHashMap('id', action.payload);
    const listIds = getKeys('id', action.payload);

    return {
        ...state,
        list,
        listIds,
        status: listIds.length > 0 ? 'HAS_RECORDS' : 'NO_RECORDS'
    }
};

reducers[TODO_RECORDS_FAILURE] = (state, action) => {
    return {
        ...state,
        status: 'ERROR'
    }
};

reducers[TODO_MUTATION_REQUEST] = (state, action) => {
    const selectedTodo = state.list[action.payload.id]

    return {
        ...state,
        list: {
            ...state.list,
            [action.payload.id]: {
                ...selectedTodo,
                isMutating: true
            }
        }
    }
}

reducers[TODO_MUTATION_SUCCESS] = (state, action) => {
    const { [action.payload.id]: _, ...list } = state.list;
    const listIds = state.listIds.filter(x => x !== action.payload.id)

    return {
        ...state,
        list,
        listIds
    }
}

reducers[TODO_MUTATION_FAILURE] = (state, action) => {
    const selectedTodo = state.list[action.payload.id]

    return {
        ...state,
        list: {
            ...state.list,
            [action.payload.id]: {
                ...selectedTodo,
                isMutating: false
            }
        }
    }
}

export default (state = initialState, action) =>
    action.type in reducers ? reducers[action.type](state, action) : state;
